<div class="card dialog-box">
  <div class="card-header">
    Date Range
    <span class="float-end" (click)="onDismiss()">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

  <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>

  <div class="row row-cols-1 row-cols-lg-2 g-2">
    <div class="form-floating">
      <input
        id="startDay"
        class="form-control"
        type="datetime-local"
        placeholder="Pick Date & Time"
        [(ngModel)]="startDateTime"
      /><label for="startDay"> Start</label>
    </div>

    <div class="form-floating">
      <input
        id="endDay"
        type="datetime-local"
        class="form-control"
        placeholder="Pick Date & Time"
        [(ngModel)]="endDateTime"
        [disabled]="!startDateTime"
        [min]="startDateTime"
      /><label for="endDay"> End</label>
    </div>
  </div>
  <div class="row row-cols-2 mt-5">
    <div class="col">
      <button (click)="onDismiss()" class="btn btn-dark btn-lg w-100">
        Cancel
      </button>
    </div>
    <div class="col">
      <button
        class="btn btn-primary w-100 btn-lg"
        (click)="saveDateRange()"
        [disabled]="!(endDateTime && startDateTime)"
      >
        Apply
      </button>
    </div>
  </div>
</div>
