import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
declare let L: any;

import { mapLayers } from '../../../../global.variable';
import { DeviceInfoService } from '../../../shared/services/device-info.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';

@Component({
  selector: 'gtapp-map-pins-view',
  templateUrl: './map-pins-view.component.html',
  styleUrl: './map-pins-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapPinsViewComponent implements OnInit, OnDestroy {
  markerLatLon = { lat: -26.397274, lon: 134.572989 }; // coordinates to center map on upon load
  @Input() mapFrom: any = 'jobPage';
  @Input() listData: any;
  @Input() selectedGuardUser: any;

  @Output('openItemPage') openItemPage = new EventEmitter();
  @Output('popupOpened') popupOpened = new EventEmitter();
  @ViewChild('itemDetailRef', { static: true }) itemDetailRef: any =
    TemplateRef<any>;

  marker: any;
  default_zoom = 11;
  incremental_zoom = 2;
  baselayers: any = mapLayers;
  defaultLayer = this.baselayers['Default'];

  pwaApp: boolean = Boolean(
    window.matchMedia('(display-mode: standalone)').matches
  );

  mapContainer: any;

  constructor(
    private spinnerService: LoadingSpinnerService,
    private deviceInfoService: DeviceInfoService
  ) {}
  ngOnInit(): void {}
  ngOnDestroy() {
    this.mapContainer = L.DomUtil.get(this.mapFrom);
    if (this.mapContainer != null) {
      this.mapContainer._leaflet_id = null;
    }
  }

  convertTemplateToHtml(template: TemplateRef<any>, data: any): string {
    const context = {
      $implicit: data,
      // dialogRef: { close: () => {} },
    };

    const viewRef = template.createEmbeddedView(context);
    viewRef.detectChanges();

    const element = (viewRef.rootNodes[0] as HTMLElement).outerHTML;
    viewRef.destroy();

    return element;
  }

  async ngAfterViewInit() {
    setTimeout(async () => {
      await this.loadMap();
    }, 100);
  }

  async loadMap() {
    const osmLayer = L.tileLayer(
      'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',

      {
        maxZoom: 18,
        minZoom: 6,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        attribution:
          '&copy; <a href="https://maps.google.com/help/terms_maps.html">Google Maps</a> ',
      }
    );
    let center;
    if (this.selectedGuardUser?.gps) {
      center = L.latLng(
        this.selectedGuardUser?.gps?.lat,
        this.selectedGuardUser?.gps?.lon
      );
    } else {
      let userLocation: any =
        (await this.deviceInfoService.getGpsCoordinates()) || this.markerLatLon;
      center = L.latLng(userLocation?.lat, userLocation?.lon);
    }

    this.mapContainer = L.DomUtil.get(this.mapFrom);
    if (this.mapContainer != null) {
      this.mapContainer._leaflet_id = null;
    }

    let _map = L.map(this.mapFrom, {
      center: center,
      dragging: true,
      zoom: this.default_zoom,
      layers: [osmLayer],
      fullscreenControl: true,
      fullscreenControlOptions: {
        // optional
        title: 'Show me the fullscreen !',
        titleCancel: 'Exit fullscreen mode',
      },
    } as any);
    L.control.layers(this.baselayers, {}).addTo(_map);
    this.baselayers['Default'].addTo(_map);
    const markerIcon = L.icon({
      iconSize: [32, 32],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],
      iconUrl: 'assets/images/gt-pin.avif',
    });
    const markerCluster = new (L as any).MarkerClusterGroup({});
    let guardEventOrder = 0;

    this.listData?.forEach((item: any) => {
      let marker: any;
      item.mapFrom = this.mapFrom;
      const popupContent = this.convertTemplateToHtml(this.itemDetailRef, item);
      if (this.mapFrom == 'guardLocationHistory') {
        let usernameInitials: any;

        usernameInitials = item?.user_name
          ?.split(' ')
          .map((name: any) => name.charAt(0))
          .join(''); //

        const html = item?.profile_img_url
          ? `<img src="${item?.profile_img_url}" alt="User Image" class="custom-marker-icon-img" />`
          : usernameInitials;

        let myIcon = L.divIcon({
          className: 'custom-marker-icon',
          html: html,
        });
        marker = L.marker([item?.gps?.lat, item?.gps?.lon], {
          icon: myIcon,
        }).addTo(_map);
        markerCluster.addLayer(marker);
        if (this.selectedGuardUser?.user_id === item?.user_id) {
          marker
            .bindPopup(popupContent, {
              closeButton: false,
              keepInView: true,
            })
            .openPopup();
        } else {
          marker.bindPopup(popupContent, {
            closeButton: false,
            keepInView: true,
          });
        }
      } else {
        marker = L.marker([item?.latitude, item?.longitude], {
          icon: markerIcon,
        }).addTo(_map);
        markerCluster.addLayer(marker);
        marker.bindPopup(popupContent, {
          closeButton: false,
          keepInView: true,
        });
      }

      marker.on('popupopen', () => {
        _map.setView(marker.getLatLng(), 18);
        const popupElement: any = marker?.getPopup()?.getElement();
        if (this.mapFrom == 'guardLocationHistory') {
          this.popupOpened.emit(item);
        }
        popupElement.addEventListener('click', (event: any) => {
          this.openItemPage.emit(item);
          // Zoom to level 18 when a marker is clicked
        });
      });
    });
    _map.on('zoom', (event: any) => {
      if (event.target?._zoom) this.default_zoom = event.target?._zoom;
    });

    _map.addLayer(markerCluster);
    _map.dragging.enable();
    if (this.pwaApp == true) {
      let embeddedLinkElement: any = document.querySelectorAll(
        '.leaflet-control-attribution.leaflet-control'
      );
      if (embeddedLinkElement) {
        embeddedLinkElement.forEach((element: any) => {
          element.classList.add('disable-pointer-events');
        });
      }
    }
    this.spinnerService.hide();
  }
}
