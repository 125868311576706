import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'gtapp-pagination-control',
  templateUrl: './pagination-control.component.html',
  styleUrl: './pagination-control.component.scss',
})
export class PaginationControlComponent implements OnInit {
  @Input() pageSize: any; //How many entries the page will show
  @Input() pageNum: any; //In which page the user is now
  @Input() previous: any; //How many entries we have to laeave or in previous page
  @Input() totalRows: any; // Total data in the backend
  totalPages: number = 0; // Calculate the pages i.e how many pages will be there

  @Output() onChangePagination = new EventEmitter(); //On change the size of page from dropdown

  paginationValues = [5, 10, 15, 20, 25];
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    this.totalPages = Math.ceil(this.totalRows / this.pageSize);
  }

  ngOnInit(): void {
    this.totalPages = Math.ceil(this.totalRows / this.pageSize);
  }
  changePagination() {
    this.onChangePagination.emit({
      previous: Number(this.previous),
      pageNum: Number(this.pageNum),
      pageSize: Number(this.pageSize),
    });
  }
}
