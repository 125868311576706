// Used for staging to enable push notifications and dev settings
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  devMode: true,
  recaptcha: {
    siteKey: '6LdWDLslAAAAANDVKlGD6zuH7hORGSwUZDtV0Ftk',
  },
  apiUrls: 'http://127.0.0.1:8000',
  vapIDPKey:
    'BIso7hUSb-7JwRJkDW7K6OZVBpasJxRcNjPr3_CdJsFKJWfZDd_bSiGDdu63O7EpkhWsHA8Ps6oUQS-bjJVT90g',
};
