import Dexie from 'dexie';

class GTDatabase extends Dexie {
  // indexed db store name
  gtSavedData!: Dexie.Table<any>;
  userData!: Dexie.Table<any>;
  cachedData!: Dexie.Table<any>;

  constructor() {
    try {
      // indexed db database name
      super('gt-app');
      this.version(0.1).stores({
        gtSavedData: '++indexDBId, eventKey',
        userData: '',
        cachedData: ',shortPath',
      });
      this.gtSavedData = this.table('gtSavedData');
      this.userData = this.table('userData');
      this.cachedData = this.table('cachedData');
    } catch (error) {}
  }
}

export const gTDB = new GTDatabase();
