<div class="card dialog-box">
  <div class="card-header">
    Change Account
    <span class="float-end" (click)="onCloseDialogue()">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>
  <div>
    <div class="row row-cols-1 text-center gy-2">
      <div
        *ngFor="let subscriber of userLinkedSubscribers"
        (click)="selectSubscriber(subscriber)"
        class="col"
      >
        <div class="gt-card p-3 border-primary">
          <div class="cursor-pointer">
            <div class="fw-bold">
              {{ subscriber.name }}
            </div>
            <div class="small-font">
              {{ subscriber?.address?.full_address }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <button class="btn btn-dark btn-lg w-100" (click)="onCloseDialogue()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
