<div class="card dialog-box">
  <div class="card-header">
    Change Password
    <span class="float-end" (click)="onCloseDialogue()">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>
  <div class="my-2">
    <form [formGroup]="passwordForm" appFormEnterAction>
      <div class="row row-cols-1 g-3">
        <div>
          <div class="input-group">
            <div class="form-floating">
              <input
                class="form-control"
                [type]="showPassword ? 'text' : 'password'"
                name="password"
                id="password"
                formControlName="password"
                placeholder="New Password"
                minlength="8"
                [ngClass]="{
                  'is-invalid':
                    passwordForm.controls['password'].dirty &&
                    passwordForm.controls['password'].invalid,
                  
                }"
              />
              <label for="password">New Password</label>
            </div>
            <span
              class="input-group-text"
              *ngIf="showPassword === true"
              (click)="toggleShowPassword()"
            >
              <i class="fa-regular fa-eye"></i>
            </span>
            <span
              class="input-group-text"
              *ngIf="showPassword === false"
              (click)="toggleShowPassword()"
            >
              <i class="fa-regular fa-eye-slash"></i>
            </span>
          </div>

          <small
            *ngIf="passwordForm.controls['password'].hasError('minlength')"
            class="text-danger"
          >
            Must have at least 8 characters
          </small>
        </div>
        <div>
          <div class="form-floating">
            <input
              class="form-control"
              type="password"
              name="confirm_password"
              id="confirm_password"
              formControlName="confirm_password"
              placeholder="Confirm Password"
              minlength="8"
              [ngClass]="{
                'is-invalid':
                  passwordForm.controls['confirm_password'].dirty &&
                  passwordForm.controls['confirm_password'].invalid,
                
              }"
            />
            <label for="confirm_password">Confirm Password</label>
          </div>
          <small
            class="text-danger"
            *ngIf="
              passwordForm.controls['confirm_password'].value &&
              passwordForm.controls['confirm_password'].invalid
            "
          >
            Passwords do not match
          </small>
        </div>
      </div>

      <div class="row row-cols-2 mt-3">
        <div class="col">
          <button
            type="button"
            class="btn btn-dark btn-lg w-100"
            (click)="onCloseDialogue()"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            (click)="changePassword()"
            class="btn btn-primary btn-lg w-100"
            [disabled]="!passwordForm.valid"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
