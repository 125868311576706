import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DIALOG_DATA } from '../../../../global.variable';
import { ClientsService } from '../../../pages/pages/clients/clients.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { ToasterService } from '../../services/toaster.service';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';

@Component({
  selector: 'gtapp-add-edit-response-type',
  templateUrl: './add-edit-response-type.component.html',
  styleUrl: './add-edit-response-type.component.scss',
})
export class AddEditResponseTypeComponent implements OnInit {
  @Input() updateView: any;
  @Input() responseData: any;
  @Input() name: any;
  responseForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
  });
  constructor(
    private clientService: ClientsService,
    private spinnerService: LoadingSpinnerService,
    @Optional()
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public dialogueData: any,

    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.updateView = this.dialogueData?.updateView;
    this.name = this.dialogueData?.name;
    this.responseData = this.dialogueData?.responseData;
    if (this.updateView === false) {
      this.responseForm.reset();
      if (this.name) {
        this.responseForm.controls['name'].setValue(this.name);
      }
    } else {
      this.responseForm.controls['name'].setValue(this.responseData.name);
    }
  }
  updateData() {
    if (this.responseForm.valid) {
      var requestData = this.responseForm.value;
      this.spinnerService.show();
      if (!this.updateView) {
        this.clientService
          .createResponseType(requestData)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();

              this.onCloseDialogue(response);
              this.toasterService.setMessage({
                errorMessage: '',
                successMessage: response['message'],
              });
              this.responseForm.reset();
              this.updateView = false;
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      } else {
        requestData['id'] = this.responseData.id;
        this.clientService
          .updateResponseTypeDetailById(requestData)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();

              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });

              this.onCloseDialogue(response);

              this.updateView = false;
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      }
    }
  }
  onCloseDialogue(value?: any) {
    this.dialogRef.close(value);
  }
}
