import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddEditClientComponent } from './components/add-edit-client/add-edit-client.component';
import { AddEditIncidentTypeComponent } from './components/add-edit-incident-type/add-edit-incident-type.component';
import { AddEditLicenseDetailsComponent } from './components/add-edit-license-details/add-edit-license-details.component';
import { AddEditMonitoringCompanyComponent } from './components/add-edit-monitoring-company/add-edit-monitoring-company.component';
import { AddEditResponseTypeComponent } from './components/add-edit-response-type/add-edit-response-type.component';
import { AddEditSiteComponent } from './components/add-edit-site/add-edit-site.component';
import { AddressLookupComponent } from './components/address-lookup/address-lookup.component';
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { CardListComponent } from './components/card-list/card-list.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ClientSiteContactsComponent } from './components/client-site-contacts/client-site-contacts.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ContactComponent } from './components/contact/contact.component';
import { DatetimePickerComponent } from './components/datetime-picker/datetime-picker.component';
import { DragMapComponent } from './components/drag-map/drag-map.component';
import { EventSchedulerComponent } from './components/event-scheduler/event-scheduler.component';
import { GtTableComponent } from './components/gt-table/gt-table.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { InfiniteListComponent } from './components/infinite-list/infinite-list.component';
import { MapLeafletComponent } from './components/map-leaflet/map-leaflet.component';
import { MapPinsViewComponent } from './components/map-pins-view/map-pins-view.component';
import { ModelDialogueService } from './components/modal-dialogue/model-dialogue.service';
import { MultipleSubscribersComponent } from './components/multiple-subscribers/multiple-subscribers.component';
import { PaginationControlComponent } from './components/pagination-control/pagination-control.component';
import { PrompterComponent } from './components/prompter/prompter.component';
import { PwaInstallationGuideComponent } from './components/pwa-installation-guide/pwa-installation-guide.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { SaveNewAddressComponent } from './components/save-new-address/save-new-address.component';
import { SchedulerComponent } from './components/scheduler/scheduler.component';
import { SelectedEventDetailComponent } from './components/selected-event-detail/selected-event-detail.component';
import { UploadTypeComponent } from './components/upload-type/upload-type.component';
import { WebcamImageComponent } from './components/web-cam-image/web-cam-image.component';
import { WhatsNewComponent } from './components/whats-new/whats-new.component';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { MiscPipe } from './pipes/misc.pipe';
import { DataCheckService } from './services/data-check.service';
import { DynamicScriptLoadService } from './services/dynamic-script-load.service';
import { LoadingSpinnerService } from './services/loading-spinner.service';

@NgModule({
  declarations: [
    MapPinsViewComponent,
    CardListComponent,
    GtTableComponent,
    PaginationControlComponent,
    QrCodeComponent,
    ConfirmDialogComponent,
    MapLeafletComponent,
    AddEditSiteComponent,
    AddEditClientComponent,
    SaveNewAddressComponent,
    DragMapComponent,
    SchedulerComponent,
    WebcamImageComponent,
    UploadTypeComponent,
    AddEditMonitoringCompanyComponent,
    ContactComponent,
    AddEditResponseTypeComponent,

    AddEditLicenseDetailsComponent,
    PrompterComponent,
    DatetimePickerComponent,
    EventSchedulerComponent,
    SelectedEventDetailComponent,
    MiscPipe,
    ChangePasswordComponent,
    MultipleSubscribersComponent,
    PwaInstallationGuideComponent,
    AutoCompleteComponent,
    InfiniteListComponent,
    BarChartComponent,
    ImagePreviewComponent,
    ClientSiteContactsComponent,
    WhatsNewComponent,
    AddEditIncidentTypeComponent,
    CustomDatePipe,
    AddressLookupComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [
    MapPinsViewComponent,
    CardListComponent,
    GtTableComponent,
    PaginationControlComponent,
    QrCodeComponent,
    MapLeafletComponent,
    AddEditSiteComponent,
    ConfirmDialogComponent,
    AddEditClientComponent,
    SaveNewAddressComponent,
    DragMapComponent,
    SchedulerComponent,
    WebcamImageComponent,
    UploadTypeComponent,
    AddEditMonitoringCompanyComponent,
    ContactComponent,
    SchedulerComponent,
    SelectedEventDetailComponent,
    MiscPipe,
    PrompterComponent,
    AutoCompleteComponent,
    InfiniteListComponent,
    BarChartComponent,
    ImagePreviewComponent,
    ClientSiteContactsComponent,
    CustomDatePipe,
    AddEditLicenseDetailsComponent,
    AddressLookupComponent,
  ],
})
export class SharedModule {
  static forRoot() {
    return {
      NgModule: SharedModule,
      providers: [
        DataCheckService,
        DynamicScriptLoadService,
        LoadingSpinnerService,
        ModelDialogueService,
      ],
    };
  }
}
