import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import {
  getStartEndDateTime,
  globalChartHeight,
  isLargeScreen,
} from '../../../global.variable';
import {
  getEventAssignees,
  getJobStatus,
  trashIconIf,
} from '../../../roster-common-functions';

@Pipe({
  name: 'misc',
})
export class MiscPipe implements PipeTransform {
  barChartDefaultStyle = {
    'width': '100%',
    'background-color': 'var(--card-bg-color)',
    'background-image': 'var(--card-bg-gradient)',
  };
  largeView: Boolean = isLargeScreen;
  formatDateWithoutTime = (date: any) => {
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  };

  maxChartSize: number = globalChartHeight; // in pixels
  transform(value: any, action: string, ...args: unknown[]) {
    switch (action) {
      case 'getHistory':
        return value?.history_changes;
      case 'promptValue':
        return value?.value;

      case 'sliceWord':
        const worldSplitLength: any = args[0] || 10;
        return value?.length > worldSplitLength
          ? `${value?.slice(0, worldSplitLength)}...`
          : value;

      case 'promptLabel':
        const promptList: any = args[0];
        const promptInfo: any = promptList?.find(
          (promptItem: any) => promptItem?.value === value?.promptKeyValue
        );
        return promptInfo?.promptLabel || value?.promptKeyValue;

      case 'convertIntoCent':
        return Math.round(value * 100 * 100) / 100;

      case 'convertIntoDollar':
        return Math.round(value * 100) / 100;

      case 'lastCommentEditedDateTime':
        let sortedComments = value?.sort(
          (a: any, b: any) =>
            new Date(a?.updated_at).getTime() -
            new Date(b?.updated_at).getTime()
        );
        return sortedComments[sortedComments?.length - 1];

      case 'getBarStyle':
        const barChartInfo: any = args[0];
        const maxChartValue: any = args[1];
        const barChartValueKey: any = barChartInfo?.value?.name;

        const barHeight = `${
          this.maxChartSize * (value?.[barChartValueKey] / maxChartValue)
        }px`;
        let newStyle = {
          ...this.barChartDefaultStyle,
          ...barChartInfo?.value?.style,
          height: barHeight,
        };

        return newStyle;

      case 'getBarLabel':
        const barChartLabelInfo: any = args[0];
        const barChartLabelKey: any = barChartLabelInfo?.label?.name;

        return value?.[barChartLabelKey];
      case 'getBarValue':
        const barChartValueInfo: any = args[0];
        const barChartValue: any = barChartValueInfo?.value?.name;

        return value?.[barChartValue];

      case 'getFilterHeader':
        if (!value?.header) {
          return value?.name?.split('_')?.join(' ');
        }
        return value?.header;

      case 'getScheduleTimeRange':
        if (!value) return 'Alarm Response';
        function stripSeconds(time: any) {
          const timeList = time?.split(':');
          timeList?.pop();
          return timeList?.join(':');
        }
        return `${stripSeconds(value?.start_time)} -  ${stripSeconds(
          value?.end_time
        )}`;

      case 'latLonCenter':
        let lastItem = value[value?.length - 1];

        return { lat: lastItem?.latitude, lon: lastItem?.longitude };

      case 'getWeekDay':
        const date = new Date(value);
        let weekDays = [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ];
        return this.largeView
          ? weekDays[date.getDay()]
          : weekDays[date.getDay()]?.slice(0, 2);

      case 'getJobStatus':
        return getJobStatus(value, args[0]);

      case 'getEventAssignees':
        return getEventAssignees(value, args[0], args[1]);

      case 'trashIconIf':
        return trashIconIf(value, args[0], args[1]);

      case 'getConflictSchedules':
        return value?.conflictSchedules || [];

      case 'getPatrolCheckpointCount':
        const date1: any = args[0];
        return value?.patrol_route_details?.schedule_details?.checkpoint_count
          ? value?.patrol_route_details?.schedule_details?.checkpoint_count
          : date1
          ? value?.patrol_route_details?.schedule_details?.[
              formatDate(new Date(date1), 'yyyy-MM-dd', 'en_US')
            ]?.checkpoint_count
          : value?.patrol_route_details?.checkpoint_count;

      case 'getPatrolCheckpointName':
        const date2: any = args[0];
        return value?.patrol_route_details?.schedule_details?.name
          ? value?.patrol_route_details?.schedule_details?.name
          : date2
          ? value?.patrol_route_details?.schedule_details?.[
              formatDate(new Date(date2), 'yyyy-MM-dd', 'en_US')
            ]?.name
          : value?.patrol_route_details?.name;

      case 'getTimeDifference':
        function pad(num: number): string {
          return num < 10 ? `0${num}` : `${num}`;
        }
        function getTimerValue(startTime: any, endTime: any) {
          let diffInTime = endTime?.getTime() - startTime?.getTime();

          if (!diffInTime) return '0';
          let seconds = Math.floor(diffInTime / 1000);
          let hours = Math.floor(seconds / 3600);
          let minutes = Math.floor((seconds % 3600) / 60);

          return `${hours}:${pad(minutes)}`;
        }

        let [startTime, endTime] = getStartEndDateTime(value);

        return getTimerValue(startTime, endTime);
      case 'jsonParse':
        function isDictionary(item: any): boolean {
          return (
            item && typeof item === 'object' && item.constructor === Object
          );
        }
        try {
          return isDictionary(value) ? value : JSON.parse(value);
        } catch (error) {
          return {};
        }
      case 'promptBreakValue':
        return Array.isArray(value) ? value.join(', ') : value;

      case 'getExpiringLicensesByMonth':
        const yearWiseDict: any = args[0];

        return yearWiseDict?.[value?.slice(0, 3)] || 0;

      case 'getWeatherFromDate':
        const selectedDate: any = args[0];

        if (!selectedDate) return null;

        return value?.[
          formatDate(new Date(selectedDate), 'yyyy-MM-dd', 'en_US')
        ];
      case 'excludeDateFilters':
        const excludeDateFilters = [
          ...value?.filter((item: any) => !item?.datetimeObj),
        ];
        return excludeDateFilters?.length > 0;
    }

    return null;
  }
}
