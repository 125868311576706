<div *ngIf="!checkPointForm.value?.qr_text" class="dialog-box card">
  <ng-container *ngIf="isIphonePwa">
    <div
      (click)="toggleCollapse()"
      class="btn btn-sm btn-secondary text-center mb-2"
    >
      Help
    </div>
    <div class="small collapse" id="collapseId">
      If you don't see a camera feed, try the following:
      <ol>
        <li>
          Check your browser permissions and ensure camera access is allowed.
        </li>
        <li>Turn your phone off and on via Settings > General > Shut Down.</li>
        <li>Run Guard Tracker in a new tab in your web browser.</li>
      </ol>
    </div>
  </ng-container>

  <ng-container *ngIf="!cameraFeedDetected && !isIphonePwa">
    <div class="alert alert-primary mt-5">
      <div>
        There was a problem getting your camera feed, please try the
        following:<br />
        1. Check your browser permissions and ensure camera access is
        allowed.<br />
        2. Try running Guard Tracker in a new browser tab.<br />
        3. Power off your device, wait 5 seconds, power on and try again.
      </div>
    </div>
  </ng-container>

  <div>
    <div
      class="camera-change-btn"
      *ngIf="!videoElementHidden && cameraDevices?.length > 1"
    >
      <button class="btn btn-light mb-1" (click)="cycleCamera()">
        <i class="fa-solid fa-2x fa-camera-rotate"></i>
      </button>
    </div>

    <div id="html5-qr-reader" class="html5-qr-reader mb-2"></div>
    <div *ngIf="zoomRange?.max && zoomRange?.min">
      <label class="mx-2"> Zoom</label>
      <label class="float-end mx-2"> {{ zoomRange.value }} x</label>
      <input
        style="width: 100%"
        type="range"
        [min]="zoomRange?.min"
        placeholder="Zoom"
        [max]="zoomRange?.max"
        [(ngModel)]="zoomRange.value"
        (mouseup)="applyZoom()"
        (touchend)="applyZoom()"
      />
    </div>
  </div>
  <div [ngClass]="html5torch ? 'row row-cols-2 mt-2' : 'row row-cols-1 mt-2'">
    <div class="col">
      <button class="btn btn-danger btn-lg w-100" (click)="onCloseDialogue()">
        Cancel
      </button>
    </div>
    <div class="col" *ngIf="html5torch">
      <button
        class="btn btn-primary btn-lg w-100"
        (click)="turnOffOnTorchHTML5(devieTorchOn ? 'off' : 'on')"
      >
        {{ devieTorchOn ? "Turn off Torch" : "Turn on Torch" }}
      </button>
    </div>
  </div>
</div>

<ng-container *ngIf="checkPointForm.value?.qr_text && showForm">
  <div class="card dialog-box">
    <div class="card-header">
      <div>
        Register QR Code
        <span class="float-end" (click)="onCloseDialogue()">
          <i class="fa-solid fa-xmark"></i>
        </span>
      </div>

      <div
        *ngIf="checkPointForm.value?.qr_text"
        class="text-center fw-semibold small"
      >
        {{
          checkPointForm.value?.qr_text.length == 44
            ? checkPointForm.value?.qr_text.slice(32)
            : checkPointForm.value?.qr_text
        }}
      </div>
    </div>
    <!--register QR html code-->

    <!-- register an existing QR to a new checkpoint-->
    <div class="mt-3">
      <div *ngIf="offlineMode">
        <div class="alert alert-danger small">
          Warning: Connection to server failed. This QR code will be uploaded
          later.
        </div>
      </div>
      <form [formGroup]="checkPointForm">
        <ng-container *ngIf="currentStep === 1">
          <div class="row row-cols-1 g-2">
            <div class="form-floating">
              <input
                class="form-control"
                formControlName="name"
                [maxlength]="100"
                type="text"
                placeholder="Checkpoint Name"
                autofocus
              />
              <label for="inputService">Checkpoint Name</label>
            </div>

            <div>
              <gtapp-auto-complete
                [data]="siteListData"
                placeHolder="Site"
                searchKeyword="company_name"
                [initialValue]="checkPointForm.value.site"
                [itemTemplate]="siteTemplate"
                [addInitialKey]="(isAdmin || isDispatchUser) && !offlineMode"
                (inputChanged)="onSiteSearch($event)"
                (selected)="onSiteSelect($event)"
              >
              </gtapp-auto-complete>
            </div>

            <ng-template #siteTemplate let-item>
              @if (item?.key === 0) {
              <ng-container *ngIf="!offlineMode">
                <div>Add New Site</div>
              </ng-container>

              } @else {
              <div>
                <div class="fw-medium">
                  {{ item?.company_name }} [{{ item?.company?.company_name }}]
                </div>

                <div class="small-font text-secondary">
                  {{ item?.address?.full_address }}
                </div>
              </div>
              }
            </ng-template>

            <div class="form-floating">
              <input
                class="form-control"
                id="description"
                formControlName="description"
                type="text"
                placeholder="Description (Optional)"
              />
              <label for="inputAssignee">Description (Optional)</label>
            </div>

            <div class="mt-4">
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="registerScan"
                  (change)="registerScan = !registerScan"
                  [checked]="registerScan"
                />
                <label class="form-check-label" for="registerScan"
                  >Scan Checkpoint After Adding</label
                >
              </div>
              <div class="form-check mb-3" *ngIf="isAdmin || isDispatchUser">
                <input
                  class="form-check-input"
                  [disabled]="offlineMode"
                  type="checkbox"
                  id="lockQRCp"
                  [checked]="checkPointForm.value.is_locked"
                  (change)="showHideAddressFields($event)"
                />
                <label class="form-check-label" for="lockQRCp"
                  >Set Address & Lock Checkpoint</label
                >
                <span
                  class="ms-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="When ticked, checkpoint can only be scanned at checkpoint location."
                >
                  <i class="fa-solid fa-circle-info"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="row row-cols-2">
            <div class="col">
              <button
                type="button"
                (click)="onCloseDialogue()"
                class="btn btn-dark btn-lg w-100"
              >
                Cancel
              </button>
            </div>
            <div
              class="col"
              *ngIf="
                checkPointForm.value.is_locked && (isAdmin || isDispatchUser)
              "
            >
              <button
                type="button"
                class="btn btn-primary btn-lg w-100"
                [disabled]="!checkPointForm.valid"
                (click)="showMapMethod(); currentStep = 2"
              >
                Select Location
              </button>
            </div>
            <div class="col" *ngIf="!checkPointForm.value.is_locked">
              <button
                type="submit"
                [disabled]="!checkPointForm.valid"
                class="btn btn-primary btn-lg w-100"
                (click)="onClickSubmit()"
              >
                Add Checkpoint
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="currentStep === 2">
          <div class="row row-cols-2 my-2">
            <div class="col">
              <button
                (click)="currentStep = 1; initializeToolTip()"
                class="btn btn-dark btn-lg w-100"
              >
                Back
              </button>
            </div>
            <div class="col">
              <button
                [disabled]="!checkPointForm.valid"
                class="btn btn-primary btn-lg w-100"
                (click)="onClickSubmit()"
              >
                Add Checkpoint
              </button>
            </div>
          </div>

          <div *ngIf="checkpointGps?.lat">
            <div class="mb-2">
              <gtapp-address-lookup
                [addressForm]="checkPointForm"
                (updateAddress)="addressSelected($event)"
              >
              </gtapp-address-lookup>
            </div>

            <gtapp-map-leaflet
              [latLon]="checkpointGps"
              [mapId]="'checkpointEditmapid'"
              [cursorDraggable]="true"
              (emitData)="emitData($event, true)"
              (updateMinDistance)="updateMinDistance($event)"
            ></gtapp-map-leaflet>
            <div
              *ngIf="
                (checkPointForm.value?.latitude &&
                  checkPointForm.value?.longitude) ||
                (checkpointGps?.lat && checkpointGps?.lon)
              "
              class="text-center small"
            >
              <div>Drag the marker to set coordinates</div>
            </div>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</ng-container>
