import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { DIALOG_DATA } from '../../../../global.variable';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';

@Component({
  selector: 'gtapp-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrl: './whats-new.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatsNewComponent implements OnInit {
  latestVersion: any;

  constructor(
    protected dialogRef: DialogRef,

    @Inject(DIALOG_DATA) public dialogueData: any
  ) {}
  ngOnInit(): void {
    if (this.dialogueData) {
      this.latestVersion = this.dialogueData?.latestVersion;
    }
  }
  onDismis(close?: any) {
    // Close the dialog, return false
    this.dialogRef.close(close);
  }
}
