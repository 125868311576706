import { Component, Inject, Input, Optional } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DIALOG_DATA } from '../../../../global.variable';
import { IncidentsService } from '../../../pages/pages/incidents/incidents.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { ToasterService } from '../../services/toaster.service';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';

@Component({
  selector: 'gtapp-add-edit-incident-type',
  templateUrl: './add-edit-incident-type.component.html',
  styleUrl: './add-edit-incident-type.component.scss',
})
export class AddEditIncidentTypeComponent {
  @Input() incidentTypeData: any;
  @Input() name: any;
  incidentTypeForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
  });
  constructor(
    private incidentService: IncidentsService,
    private spinnerService: LoadingSpinnerService,
    @Optional()
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public dialogueData: any,

    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.name = this.dialogueData?.name;
    this.incidentTypeData = this.dialogueData?.incidentTypeData;
    if (!this.incidentTypeData?.id) {
      this.incidentTypeForm.reset();
      if (this.name) {
        this.incidentTypeForm.controls['name'].setValue(this.name);
      }
    } else {
      this.incidentTypeForm.controls['name'].setValue(
        this.incidentTypeData.name
      );
    }
  }
  updateData() {
    if (this.incidentTypeForm.valid) {
      var requestData = this.incidentTypeForm.value;
      this.spinnerService.show();
      if (!this.incidentTypeData?.id) {
        this.incidentService
          .createIncidentType(requestData)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();

              this.onCloseDialogue(response);
              this.toasterService.setMessage({
                errorMessage: '',
                successMessage: response['message'],
              });
              this.incidentTypeForm.reset();
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      } else {
        requestData['id'] = this.incidentTypeData.id;
        this.incidentService
          .updateIncidentTypeDetailById(requestData)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();

              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });

              this.onCloseDialogue(response);
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      }
    }
  }
  onCloseDialogue(value?: any) {
    this.dialogRef.close(value);
  }
}
