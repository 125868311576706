<div class="card dialog-box">
  <div class="card-header">
    Change Log
    <span (click)="onDismis()" class="float-end">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

  <div>
    <ng-template
      *ngTemplateOutlet="
        versionInfoTemplate;
        context: { value: latestVersion?.last_item }
      "
    ></ng-template>
  </div>

  <ng-container *ngIf="latestVersion?.previous_changes?.length">
    <hr />
    <!--previous version updates-->
    <div
      *ngFor="let version of latestVersion?.previous_changes; let l = last"
      class="small"
    >
      <ng-template
        *ngTemplateOutlet="versionInfoTemplate; context: { value: version }"
      ></ng-template>

      <hr *ngIf="!l" />
    </div>
  </ng-container>
</div>

<ng-template let-dataItem="value" #versionInfoTemplate>
  <div class="row row-cols-2">
    <div class="col">
      <dl>
        <dt>Version</dt>
        <dd class="fw-semibold">v{{ dataItem?.version }}</dd>
      </dl>
    </div>
    <div class="col">
      <dl>
        <dt>Released</dt>
        <dd>
          {{ dataItem?.updated_at | customDate : "d MMM y" }}
        </dd>
      </dl>
    </div>
  </div>

  <dl>
    <dd class="gt-multi-line small">
      {{ dataItem?.commit_messages }}
    </dd>
  </dl>
</ng-template>
