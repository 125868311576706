import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { DIALOG_DATA } from '../../../../global.variable';
import { ToasterService } from '../../services/toaster.service';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';

@Component({
  selector: 'gtapp-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss',
})
export class ConfirmDialogComponent implements OnInit {
  @Output('emitData') emitData = new EventEmitter();
  title: any;
  message: any;
  messageStyle: any;
  body: any;
  bodyData: any;
  confirmation: boolean = false;
  confirmNumber: any;
  randomNUmber: number = 0;
  valid: boolean = false;
  alertDanger: boolean = true;
  constructor(
    protected dialogueRef: DialogRef,
    @Inject(DIALOG_DATA) public dialogData: any,
    private toasterService: ToasterService
  ) {} // private toastrService: NbToastrService //

  ngOnInit(): void {
    if (this.dialogData) {
      this.body = this.dialogData?.body;
    }
    this.randomNUmber = this.randomInteger(1000, 9999);
    var element = <HTMLInputElement>document.getElementById('confirmBtn');
    if (element) {
      element.disabled = false;
      element.focus();
    }
    this.title = this.dialogData?.title;
    this.message = this.dialogData?.message;
    this.messageStyle = this.dialogData?.messageStyle;
    this.confirmation = this.dialogData?.confirmation;
  }
  onConfirm(event: any): void {
    if (this.body) {
      this.dialogueRef.close({ data: this.bodyData, value: true });
    }
    if (this.confirmation) {
      if (this.randomNUmber == this.confirmNumber) {
        this.dialogueRef.close(true);
      } else {
        this.toasterService.setMessage({
          errorMessage: `Numbers don't match`,
          successMessage: '',
        });
      }
    }
    if (!this.confirmation) this.dialogueRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogueRef.close(false);
  }
  randomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
