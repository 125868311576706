<div *ngIf="appliedFilters?.length">
  <div
    class="row row-cols-auto gy-2"
    [ngClass]="(appliedFilters | misc : 'excludeDateFilters') ? 'mb-3' : ''"
  >
    <div *ngFor="let filter of appliedFilters">
      <ng-container *ngIf="!filter?.datetimeObj">
        <button class="btn btn-danger small d-flex align-items-center">
          <span class="fs-6">{{ filter?.key }} : {{ filter?.value }}</span>
          <span class="ms-4" (click)="removeFilter(filter?.key)">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </button>
      </ng-container>
    </div>
  </div>
</div>

<ng-container *ngIf="filterSortData?.length">
  @if(selectedCardDetail && filterSortData?.length===1){

  <ng-template
    *ngTemplateOutlet="
      filterDetailTemplate;
      context: { value: selectedCardDetail }
    "
  ></ng-template>

  } @else {

  <ng-container *ngFor="let selectedFilter of filterSortData">
    <ng-template
      *ngTemplateOutlet="
        filterDetailTemplate;
        context: { value: selectedFilter }
      "
    ></ng-template>
  </ng-container>

  <!-- Show Header Toggle -->
  <div class="form-check form-switch mb-2" *ngIf="isSuperUser">
    <input
      class="form-check-input"
      type="checkbox"
      [checked]="showHeader"
      data-toggle="toggle"
      (change)="showHideHeader($event)"
      id="header"
    />
    <label class="form-check-label" for="header">Show Header</label>
  </div>

  }

  <!-- <ng-container *ngIf="filterSortData?.length > 1">
    <div class="mt-2 mb-2 d-flex justify-content-around">
      <button (click)="showNextCardDetail()" class="btn btn-primary">
        <i class="fa fa-arrow-down" aria-hidden="true"></i>
      </button>
    </div>
  </ng-container> -->
</ng-container>

<div
  class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-3 row-cols-xxxl-6 mb-3"
>
  <!-- Card Sections split into multiple sections based on screen size -->
  <!-- row-cols-md-2 row-cols-xxl-3 row-cols-xxxl-6 -->
  <div *ngFor="let data of cardData; let i = index" class="h-100">
    <div
      class="d-flex justify-content-between gt-card"
      role="button"
      (click)="onRowClick(data)"
    >
      <div class="flex-grow-1">
        <div
          *ngFor="let indCard of mobileCardDetails?.individualDetails"
          class="d-block"
        >
          <!-- Style whole row either based on condition or common to all -->
          <span
            [ngStyle]="
              indCard?.conditionalRowStyle
                ? indCard?.conditionalRowStyle(data)
                : indCard?.cardRowStyle
            "
          >
            <span
              *ngIf="
                showHeader &&
                (indCard.nestedValue
                  ? indCard.nestedValue(data)
                  : data[indCard.name]) != null
              "
              [ngStyle]="
                indCard?.conditionalHeaderRowStyle
                  ? indCard?.conditionalHeaderRowStyle(data)
                  : indCard?.cardHeaderRowStyle
              "
            >
              <!-- Header content which will be passed from the backend STARTS-->
              <dt
                [ngStyle]="
                  indCard?.conditionalHeaderRowDataStyle
                    ? indCard?.conditionalHeaderRowDataStyle(data)
                    : indCard?.rowHeaderDataStyle
                "
              >
                {{
                  indCard.nestedHeaderValue
                    ? indCard.nestedHeaderValue(data)
                    : indCard.header
                }}
              </dt>
              <!-- Header content which will be passed from the backend ENDS-->
            </span>
            <!-- A row is typically divided into 3 parts: prefix, main content, and suffix. Style each of them based on conditions or common styles -->
            <span
              [ngStyle]="
                indCard?.conditionalPrefixStyle
                  ? indCard?.conditionalPrefixStyle(data)
                  : indCard?.prefixStyle
              "
            >
              <!-- Prefix (either hardcoded from UI or passed from backend via key-value pair) STARTS -->
              {{
                indCard?.prefixNestedValue
                  ? indCard?.prefixNestedValue(data)
                  : indCard?.prefix
                  ? indCard?.prefix
                  : ""
              }}
            </span>
            <!-- Prefix (either hardcoded from UI or passed from backend via key-value pair) ENDS -->
            <!-- Main content which will be passed from the backend STARTS -->
            <span
              [ngStyle]="
                indCard?.conditionalRowDataStyle
                  ? indCard?.conditionalRowDataStyle(data)
                  : indCard?.rowDataStyle
              "
            >
              <ng-container
                *ngIf="
                  indCard?.imageObj === true &&
                  (indCard.nestedValue
                    ? indCard.nestedValue(data)
                    : data[indCard.name])
                "
              >
                <img
                  [ngStyle]="
                    indCard?.conditionalImageStyle
                      ? indCard?.conditionalImageStyle(data)
                      : indCard?.imageStyle
                  "
                  src="{{
                    indCard.imageSrc
                      ? indCard.imageSrc
                      : indCard.nestedValue
                      ? indCard.nestedValue(data)
                      : data[indCard.name]
                  }}"
                />
              </ng-container>
              <ng-container *ngIf="indCard?.datetimeObj == true">
                {{
                  indCard.nestedValue
                    ? (indCard.nestedValue(data)
                      | customDate
                        : (indCard.dateTimeFormat
                            ? indCard.dateTimeFormat
                            : "d MMM y HH:mm"))
                    : (data[indCard.name]
                      | customDate
                        : (indCard.dateTimeFormat
                            ? indCard.dateTimeFormat
                            : "d MMM y HH:mm"))
                }}</ng-container
              >
              <ng-container *ngIf="!indCard?.datetimeObj && !indCard?.imageObj">
                {{
                  indCard.nestedValue
                    ? indCard.nestedValue(data)
                    : data[indCard.name]
                }}</ng-container
              >
            </span>
            <!-- Main content which will be passed from the backend ENDS -->
            <!-- Suffix (either hardcoded from UI or passed from backend via key-value pair) STARTS -->
            <span
              [ngStyle]="
                indCard?.conditionalSuffixStyle
                  ? indCard?.conditionalSuffixStyle(data)
                  : indCard?.suffixStyle
              "
            >
              {{
                indCard?.suffixNestedValue
                  ? indCard?.suffixNestedValue(data)
                  : indCard?.suffix
                  ? indCard?.suffix
                  : ""
              }}
            </span>
            <!-- Suffix (either hardcoded from UI or passed from backend via key-value pair) ENDS -->
          </span>
          <!-- Rows where objects are normal objects ENDS -->
        </div>

   
      </div>

      @if(mobileActionIcons){
      <div class="d-flex flex-column ms-auto ps-2 gap-2">
        <div *ngFor="let actionIcon of mobileActionIcons">
          <span
            class="text-end"
            (click)="
              onActionClick(data, actionIcon.condition(data).type);
              $event.stopImmediatePropagation()
            "
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="{{ actionIcon.condition(data).title }}"
          >
            <i [class]="actionIcon.condition(data).icon"></i>
          </span>
        </div>
      </div>
      }
    </div>
  </div>
</div>

<div *ngIf="cardData?.length == 0" class="my-3">
  <div class="alert alert-primary" role="alert">No Records Found</div>
</div>
<div *ngIf="otherParams?.paginationData">
  <gtapp-pagination-control
    [pageSize]="rows"
    [pageNum]="pageNum"
    [previous]="previousRows"
    [totalRows]="totalRows"
    (onChangePagination)="onChangePagination($event)"
  >
  </gtapp-pagination-control>
</div>

<ng-template #filterDetailTemplate let-selectedFilter="value">
  <div class="mb-2">
    <div
      class="col form-floating"
      *ngIf="selectedFilter?.dateRangeKey"
      (click)="openRangePicker(selectedFilter)"
    >
      <input
        class="form-control input-font-sm"
        placeholder="Showing Data Between ({{
          selectedFilter | misc : 'getFilterHeader' | titlecase
        }})"
        readonly
        [(ngModel)]="selectedFilter.dateRangeValue"
      />
      <label
        >Showing Data Between [{{
          selectedFilter | misc : "getFilterHeader" | titlecase
        }}]</label
      >
    </div>

    <div *ngIf="selectedFilter?.opendropDownBox" class="col form-floating">
      <select
        class="form-select"
        placeholder="Status"
        (selectedChange)="selectStatus($event, selectedFilter)"
      >
        <div *ngFor="let status of selectedFilter?.dropDownList()">
          {{ status?.name }}
        </div>
      </select>
    </div>
    <ng-container *ngIf="selectedFilter?.searchKey">
      @if(selectedFilter?.autoComplete){

      <gtapp-auto-complete
        [data]="autoCompleteSearchResultList"
        placeHolder="Filter by {{
          selectedFilter | misc : 'getFilterHeader' | titlecase
        }}"
        [searchKeyword]="selectedFilter?.autoComplete"
        [initialValue]="selectedFilter.searchValue"
        [itemTemplate]="autoCompleteTemplate"
        [enableSearchCloseOption]="true"
        (inputChanged)="onAutoCompleteInputSearch($event, selectedFilter)"
        (selected)="onAutoCompleteItemSelect($event, selectedFilter)"
        (submitted)="onAutoCompleteSearch($event, selectedFilter)"
      >
      </gtapp-auto-complete>

      <ng-template #autoCompleteTemplate let-item>
        <div>
          {{ item?.full_name }}
        </div>
      </ng-template>
      }@else{
      <div class="input-group">
        <div class="col form-floating">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="selectedFilter.searchValue"
            placeholder="Search"
            (keyup.enter)="searchField(selectedFilter)"
          />
          <label
            >Search
            {{ selectedFilter | misc : "getFilterHeader" | titlecase }}</label
          >
        </div>
        <span
          class="input-group-text"
          *ngIf="selectedFilter?.searchKey && cardData?.length"
          (click)="searchField(selectedFilter)"
        >
          <i class="fa-solid fa-magnifying-glass"></i>
        </span>

        <span
          class="input-group-text"
          *ngIf="
            !selectedFilter?.openSearchBox &&
            selectedFilter?.sortKey &&
            cardData?.length
          "
          (click)="sortField(selectedFilter)"
        >
          <i class="fa fa-sort"></i>
        </span>
      </div>
      }
    </ng-container>
  </div>
</ng-template>
