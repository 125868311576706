<div
  [id]="mapFrom"
  class="map"
  style="position: relative; z-index: 0; max-height: 100%; max-width: 100%"
></div>

<ng-template #itemDetailRef let-data let-siteCTempRef="dialogRef">
  <div class="cursor-pointer card m-0 p-2">
    <!-- Job Pin ToolTip Template -->
    <ng-container
      *ngIf="['mobileJobPage', 'desktopJobPage'].includes(data?.mapFrom)"
    >
      <div class="mb-2">
        <div class="job-card-small-text">
          {{ data?.service_type }} - {{ data?.job_key }}
          <div class="float-end">
            <div>{{ data?.status }}</div>
          </div>
        </div>
        <div class="fw-bold mb-1">
          {{ data?.company }}
        </div>
        <div>{{ data?.sites }}</div>
      </div>
      <div class="job-card-small-text">
        CREATED: {{ data?.created_date | customDate : "d MMM y HH:mm" }}
      </div>
      <div
        class="job-card-small-text"
        [ngStyle]="{
          color: data?.staleJob ? 'var(--color-danger-500)' : ''
        }"
      >
        UPDATED: {{ data?.modified_date | customDate : "d MMM y HH:mm" }}
      </div>
    </ng-container>

    <!-- Checkpoint Pin ToolTip Template -->
    <ng-container
      *ngIf="['mobileCpPage', 'desktopCpPage'].includes(data?.mapFrom)"
    >
      <div class="fw-bold">
        {{ data?.name }}
      </div>
      <div class="my-2">
        {{ data?.company }}
      </div>
      <div class="small-font text-secondary">
        {{ data?.address?.full_address }}
      </div>
    </ng-container>

    <!-- Incidnet Pin ToolTip Template -->
    <div
      *ngIf="
        ['mobileIncidentPage', 'desktopIncidentPage'].includes(data?.mapFrom)
      "
      [ngStyle]="{
        'background-color': data?.submitted_at ? 'var(--color-success-500)' : ''
      }"
    >
      <div class="fw-bold">
        {{ data?.incident_key }}
      </div>

      <small>
        {{ data?.full_address }}
      </small>
      <div *ngIf="data?.submitted_at">
        Submitted At :
        {{ data?.submitted_at | customDate : "d MMM y HH:mm" }}
      </div>
    </div>
    <!-- Guard Location Pin ToolTip Template -->
    <ng-container *ngIf="data?.mapFrom == 'guardLocationHistory'">
      <div>
        {{ data?.user_name }}
      </div>

      <div class="small">
        Last Seen At: {{ data?.updated_at | customDate : "d MMM y HH:mm" }}
      </div>
      <ng-container *ngIf="data?.mobile_number">
        <a
          href="tel:{{ data?.mobile_number }}"
          class="text-body-emphasis text-decoration-none"
        >
          <button
            class="btn btn-success w-100 mt-2 d-flex align-items-center justify-content-center"
          >
            <i class="fa-solid fa-phone-volume me-2"></i>
            <span>Call</span>
          </button>
        </a>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
