import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingSpinnerService {
  constructor() {}
  private loadingSubject = new BehaviorSubject<any>(false);
  isLoading$ = this.loadingSubject.asObservable();

  show(text: string = '') {
    this.loadingSubject.next(text || true);
  }

  hide() {
    this.loadingSubject.next(false);
  }
}
