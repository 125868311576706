<div class="d-block d-md-none" *ngIf="totalPages > 1">
  <!--small view devices-->
  <div class="d-flex text-center my-2">
    <div class="w-25 p-1">
      <button
        class="btn btn-secondary w-100"
        type="button"
        [disabled]="previous === 0"
        (click)="
          previous = previous - pageSize;
          pageNum = pageNum - 1;
          changePagination()
        "
      >
        <i class="fas fa-arrow-left"></i>
      </button>
    </div>
    <div class="w-50 p-1 d-flex justify-content-center align-items-center">
      {{ pageNum }} of {{ totalPages }}
    </div>
    <div class="w-25 p-1">
      <button
        class="btn btn-secondary w-100"
        type="button"
        [disabled]="previous + pageSize >= totalRows"
        (click)="
          previous = previous + pageSize;
          pageNum = pageNum + 1;
          changePagination()
        "
      >
        <i class="fas fa-arrow-right"></i>
      </button>
    </div>
  </div>
</div>
<div class="d-none d-md-block">
  <!--large view devices-->
  <div class="d-flex justify-content-end align-items-center my-2">
    <div class="me-2">Items per page</div>
    <select
      class="pagination-dropdown form-control me-2 w-auto"
      [(ngModel)]="pageSize"
      (change)="previous = 0; pageNum = 1; changePagination()"
    >
      <option
        *ngFor="let paginationval of paginationValues"
        [value]="paginationval"
      >
        {{ paginationval }}
      </option>
    </select>

    <span *ngIf="totalPages > 1">
      <button
        class="btn btn-secondary me-2"
        [disabled]="previous === 0"
        (click)="
          previous = previous - pageSize;
          pageNum = pageNum - 1;
          changePagination()
        "
      >
        <i class="fas fa-arrow-left"></i>
      </button>

      <span class="mx-1">{{ pageNum }} of {{ totalPages }}</span>

      <button
        class="btn btn-secondary ms-2"
        [disabled]="previous + pageSize >= totalRows"
        (click)="
          previous = previous + pageSize;
          pageNum = pageNum + 1;
          changePagination()
        "
      >
        <i class="fas fa-arrow-right"></i>
      </button>
    </span>
  </div>
</div>
