<div class="dialog-box card">
  <div class="card-header">
    Installation Guide
    <span class="float-end" (click)="close('close')">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

  <img
    [src]="installationSteps[currentStep]?.imgSrc"
    alt="Guard Tracker"
    class="cursor-pointer mb-2"
  />
  <ng-template *ngTemplateOutlet="topBtmBtns"></ng-template>
</div>

<ng-template #topBtmBtns>
  <div class="row row-cols-2">
    <div class="col">
      <button
        type="button"
        class="btn btn-dark w-100 btn-lg"
        (click)="currentStep > 0 ? navigate(-1) : close('close')"
      >
        {{ currentStep > 0 ? "Back" : "Cancel" }}
      </button>
    </div>
    <div class="col">
      <button
        type="button"
        class="btn btn-primary btn-lg w-100"
        (click)="
          currentStep < installationSteps?.length - 1
            ? navigate(1)
            : close('close')
        "
      >
        {{ currentStep < installationSteps?.length - 1 ? "Next" : "Done" }}
      </button>
    </div>
  </div>
</ng-template>
