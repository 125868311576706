<gtapp-card-list
  [mobileCardBackendData]="mobileCardBackendData"
  [mobileCardDetails]="mobileCardDetails"
  [otherParams]="otherParams"
  (actionClick)="actionClick.emit($event)"
  (rowClick)="rowClick.emit($event)"
  (searchColumn)="searchColumn.emit($event)"
  (sortColumn)="sortColumn.emit($event)"
></gtapp-card-list>
<div
  *ngIf="mobileCardBackendData?.length < totalRows"
  class="alert alert-primary text-center"
  role="alert"
>
  Scroll down for more
</div>
