<div class="autocomplete-container">
  <div class="input-group" #searchContainer>
    <div class="form-floating">
      <input
        autocomplete="false"
        autocomplete="off"
        class="form-control"
        autocomplete="off"
        #searchInput
        type="text"
        placeholder="{{ placeHolder }}"
        [(ngModel)]="query"
        (input)="onChange($event)"
        (focus)="handleFocus($event)"
        (blur)="handleBlur($event)"
        (keyup.enter)="inputSubmitted()"
        [id]="inputId"
      />
      <label>{{ placeHolder }}</label>
    </div>
    @if( query && enableSearchCloseOption){
    <span
      class="input-group-text"
      (click)="inputSubmitted()"
      *ngIf="!hideSearchButton"
    >
      <i class="fa-solid fa-magnifying-glass"></i>
    </span>
    <span class="input-group-text" (click)="onCloseSearch()">
      <i class="fa-solid fa-xmark"></i>
    </span>
    }
  </div>
  <!--FilteredList items-->
</div>

<ng-template #overlayTemplate>
  <div
    class="suggestions-container"
    *ngIf="isFocused && filteredList?.length"
    [ngStyle]="{ width: inputBoxWidth }"
  >
    <ul #filteredListElement>
      <li
        *ngFor="let item of filteredList; let idx = index"
        class="item"
        [ngClass]="{ 'fw-semibold': idx === 0 && addInitialKey === true }"
        (click)="selectDropDownOption(item)"
      >
        <div [class.complete-selected]="idx === selectedIdx">
          <ng-container
            *ngTemplateOutlet="
              itemTemplate;
              context: {
                $implicit: item
              }
            "
          >
          </ng-container>
        </div>
      </li>
    </ul>
  </div>
</ng-template>
