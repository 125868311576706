<div class="mt-2">
  <!-- table for desktop STARTS -->
  <div *ngIf="appliedFilters?.length">
    <div class="fw-bold mb-2">Filters</div>
    <div class="row row-cols-auto gy-2 mb-3">
      <div *ngFor="let filter of appliedFilters; let first = first">
        <button class="btn btn-danger small d-flex align-items-center">
          <span
            class="fs-6"
            (click)="
              filter?.datetimeObj && filter?.columnData
                ? openRangePicker(filter?.columnData)
                : null
            "
            >{{ filter?.key }} : {{ filter?.value }}</span
          >
          @if(filter?.datetimeObj){
          <ng-container *ngIf="!first">
            <span class="ms-4" (click)="removeFilter(filter?.key)">
              <i class="fa-solid fa-xmark"></i> </span
          ></ng-container>
          }@else {
          <ng-container>
            <span class="ms-4" (click)="removeFilter(filter?.key)">
              <i class="fa-solid fa-xmark"></i> </span
          ></ng-container>
          }
        </button>
      </div>
    </div>
  </div>
  <div [ngStyle]="tableStyle" class="mt-6">
    <table class="table table-desktop">
      <thead>
        <tr>
          <th *ngFor="let indColumn of desktopTableDetails">
            <div class="d-flex justify-content-between align-items-center">
              <span>{{ indColumn.header }} </span>
              <div class="d-flex flex-row">
                <span
                  class="mx-2"
                  *ngIf="!indColumn?.openSearchBox && indColumn?.sortKey"
                  (click)="sortField(indColumn)"
                >
                  @if( indColumn?.sortOrder == 'asc'){
                  <span>
                    <i class="fa fa-arrow-up"></i>
                  </span>

                  }@else {
                  <span>
                    <i class="fa fa-arrow-down"></i>
                  </span>

                  }
                </span>
                <span
                  *ngIf="!indColumn?.openSearchBox && indColumn?.searchKey"
                  (click)="indColumn.openSearchBox = true"
                >
                  <i class="fa fa-search"></i>
                </span>
                <span
                  (click)="
                    indColumn.openSearchBox = false;
                    indColumn.opendropDownBox = false
                  "
                  *ngIf="indColumn?.openSearchBox || indColumn?.opendropDownBox"
                >
                  <i class="fa fa-close"></i>
                </span>
                <span
                  *ngIf="!indColumn?.opendropDownBox && indColumn?.dropdownKey"
                  (click)="indColumn.opendropDownBox = true"
                >
                  <i class="fa fa-chevron-down"></i>
                </span>
                <span
                  *ngIf="indColumn?.dateRangeKey"
                  (click)="openRangePicker(indColumn)"
                >
                  <i class="fa fa-calendar"></i>
                </span>
              </div>
            </div>
            <ng-container *ngIf="indColumn?.openSearchBox">
              @if(indColumn?.autoComplete){

              <gtapp-auto-complete
                [data]="autoCompleteSearchResultList"
                placeHolder="Search  {{
                  indColumn | misc : 'getFilterHeader' | titlecase
                }}"
                [searchKeyword]="indColumn?.autoComplete"
                [initialValue]="indColumn.searchValue"
                [itemTemplate]="autoCompleteTemplate"
                [enableSearchCloseOption]="true"
                (inputChanged)="onAutoCompleteInputSearch($event, indColumn)"
                (selected)="onAutoCompleteItemSelect($event, indColumn)"
                (submitted)="onAutoCompleteItemSelect($event, indColumn)"
              >
              </gtapp-auto-complete>

              <ng-template #autoCompleteTemplate let-item>
                <div>
                  {{ item?.full_name }}
                </div>
              </ng-template>

              } @else {

              <div class="input-group flex-nowrap">
                <input
                  type="text"
                  [(ngModel)]="indColumn.searchValue"
                  placeholder="Search"
                  class="form-control w-75"
                  (keyup.enter)="
                    indColumn.searchValue ? searchField(indColumn) : null
                  "
                />
                <span
                  class="input-group-text"
                  (click)="
                    indColumn.searchValue ? searchField(indColumn) : null
                  "
                >
                  <i class="fa-solid fa-magnifying-glass"></i>
                </span>
              </div>
              }
            </ng-container>
            <div class="form-floating" *ngIf="indColumn?.opendropDownBox">
              <select
                class="form-select"
                placeholder="Status"
                [(ngModel)]="selectedStatus"
                id="selectStatus"
                (change)="selectStatus(selectedStatus, indColumn)"
              >
                <option
                  *ngFor="let status of indColumn?.dropDownList()"
                  [ngValue]="status"
                >
                  {{ status?.name }}
                </option>
              </select>
              <label for="selectStatus">Status</label>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr
          *ngFor="let indData of tableData; let i = index"
          [ngStyle]="getTableRowStyle(indData, i)"
          (click)="onTableRowClick(indData, i)"
        >
          <td
            *ngFor="let indColumn of desktopTableDetails; let j = index"
            [ngStyle]="
              indColumn?.conditionalColumnStyle
                ? indColumn?.conditionalColumnStyle(indData)
                : indColumn?.tableColumnStyle
            "
          >
            <!-- rows where objects are datetime objects STARTS-->

            <!--style whole row either based on condition or common to all -->
            <div *ngIf="indColumn?.datetimeObj == true">
              <!--a row is typically divided into 3 prefix, main content and suffix, style each of them based on conditions or common -->
              <span
                [ngStyle]="
                  indColumn?.conditionalPrefixStyle
                    ? indColumn?.conditionalPrefixStyle(indData)
                    : indColumn?.prefixStyle
                "
              >
                <!-- prefix (either hardcoded from ui or pased from backend via key-value pair) STARTS-->
                {{
                  indColumn?.prefixNestedValue
                    ? indColumn?.prefixNestedValue(indData)
                    : indColumn?.prefix
                    ? indColumn?.prefix
                    : ""
                }}
              </span>
              <!-- prefix (either hardcoded from ui or pased from backend via key-value pair) ENDS-->
              <!-- main content which will be passed from the backend STARTS-->
              <span
                [ngStyle]="
                  indColumn?.conditionalColumnDataStyle
                    ? indColumn?.conditionalColumnDataStyle(indData)
                    : indColumn?.columnDataStyle
                "
              >
                {{
                  indColumn.nestedValue
                    ? (indColumn.nestedValue(indData)
                      | customDate
                        : (indColumn.dateTimeFormat
                            ? indColumn.dateTimeFormat
                            : "d MMM y HH:mm"))
                    : (indData[indColumn.name]
                      | customDate
                        : (indColumn.dateTimeFormat
                            ? indColumn.dateTimeFormat
                            : "d MMM y HH:mm"))
                }}
              </span>
              <!-- main content which will be passed from the backend ENDS-->
              <!-- suffix (either hardcoded from ui or pased from backend via key-value pair) STARTS-->
              <span
                [ngStyle]="
                  indColumn?.conditionalSuffixStyle
                    ? indColumn?.conditionalSuffixStyle(indData)
                    : indColumn?.suffixStyle
                "
              >
                {{
                  indColumn?.suffixNestedValue
                    ? indColumn?.suffixNestedValue(indData)
                    : indColumn?.suffix
                    ? indColumn?.suffix
                    : ""
                }}
              </span>
              <!-- suffix (either hardcoded from ui or pased from backend via key-value pair) ENDS-->
            </div>
            <!-- rows where objects are datetime objects ENDS-->

            <!-- rows where objects are normal objects STARTS-->

            <!--style whole row either based on condition or common to all -->
            <div *ngIf="indColumn?.datetimeObj !== true">
              <!--a row is typically divided into 3 prefix, main content and suffix, style each of them based on conditions or common -->
              <span
                [ngStyle]="
                  indColumn?.conditionalPrefixStyle
                    ? indColumn?.conditionalPrefixStyle(indData)
                    : indColumn?.prefixStyle
                "
              >
                <!-- prefix (either hardcoded from ui or pased from backend via key-value pair) STARTS-->
                {{
                  indColumn?.prefixNestedValue
                    ? indColumn?.prefixNestedValue(indData)
                    : indColumn?.prefix
                    ? indColumn?.prefix
                    : ""
                }}
              </span>
              <!-- prefix (either hardcoded from ui or pased from backend via key-value pair) ENDS-->
              <!-- main content which will be passed from the backend STARTS-->
              <span
                [ngStyle]="
                  indColumn?.conditionalColumnDataStyle
                    ? indColumn?.conditionalColumnDataStyle(indData)
                    : indColumn?.columnDataStyle
                "
              >
                {{
                  indColumn.nestedValue
                    ? indColumn.nestedValue(indData)
                    : indData[indColumn.name] == "null" ||
                      indData[indColumn.name] == "undefined"
                    ? "N/A"
                    : indData[indColumn.name]
                }}
              </span>
              <!-- main content which will be passed from the backend ENDS-->
              <!-- suffix (either hardcoded from ui or pased from backend via key-value pair) STARTS-->
              <span
                [ngStyle]="
                  indColumn?.conditionalSuffixStyle
                    ? indColumn?.conditionalSuffixStyle(indData)
                    : indColumn?.suffixStyle
                "
              >
                {{
                  indColumn?.suffixNestedValue
                    ? indColumn?.suffixNestedValue(indData)
                    : indColumn?.suffix
                    ? indColumn?.suffix
                    : ""
                }}
              </span>
              <!-- suffix (either hardcoded from ui or pased from backend via key-value pair) ENDS-->
            </div>
            <!-- rows where objects are normal objects ENDS-->

            <div
              *ngIf="tableActionIcons && indColumn?.showAction === true"
              class="table-icon-class"
            >
              <ng-container *ngFor="let actionIcon of tableActionIcons">
                <ng-container
                  *ngIf="
                    actionIcon.condition ? actionIcon.condition(indData) : true
                  "
                >
                  <span
                    class="mx-1"
                    (click)="
                      onActionClick(
                        indData,
                        actionIcon.condition(indData).type
                      );
                      $event.stopImmediatePropagation()
                    "
                  >
                    <i
                      [class]="actionIcon.condition(indData).icon"
                      [title]="actionIcon.condition(indData).title"
                    ></i>
                  </span>
                </ng-container>
              </ng-container>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="tableData?.length == 0" class="my-3">
    <div class="alert alert-primary" role="alert">No Records Found</div>
  </div>
  <!-- table for desktop ENDS -->
  <div *ngIf="otherParams?.paginationData" class="mt-2">
    <gtapp-pagination-control
      [pageSize]="rows"
      [pageNum]="pageNum"
      [previous]="previousRows"
      [totalRows]="totalRows"
      (onChangePagination)="onChangePagination($event)"
    >
    </gtapp-pagination-control>
  </div>
</div>
