<div class="card dialog-box">
  <div class="card-header">
    {{
      editContact
        ? "Edit Contact"
        : siteData
        ? "Add Onsite Contact"
        : "Add Contact"
    }}
    <span class="float-end" (click)="onCloseDialogue(!contactData)">
      <i class="fa-solid fa-xmark"></i>
    </span>
    <div
      *ngIf="
        (((clientData?.company_name || siteData?.company_name) &&
          showAddForm) ||
          patrolReportUsers) &&
        !contactData
      "
    >
      <small>
        {{
          siteData?.company_name
            ? siteData?.company_name
            : clientData?.company_name
        }}
      </small>
    </div>
  </div>

  <div *ngIf="showAddForm || patrolReportUsers">
    <form [formGroup]="contactForm">
      <div class="row row-cols-1 g-2">
        <div>
          <gtapp-auto-complete
            [data]="contactLookupList"
            placeHolder="First Name"
            searchKeyword="user_full_name"
            [initialValue]="contactForm.value?.first_name"
            [itemTemplate]="userTemplate"
            (inputChanged)="contactLookup($event)"
            (selected)="selectContact($event); shiftFocus('lastName')"
          >
          </gtapp-auto-complete>
        </div>

        <ng-template #userTemplate let-user>
          <div>
            {{ user?.user_full_name }}
            <div
              *ngIf="user?.location_contact_id"
              text="Onsite Contact"
              class="badge"
            ></div>
            <div>
              <small>
                {{ user?.user_full_name ? user.user_email : "" }}
                {{
                  user?.user_mobile_number
                    ? " , " + user?.user_mobile_number
                    : ""
                }}
              </small>
            </div>
          </div>
        </ng-template>

        <div class="form-floating">
          <input
            class="form-control"
            type="text"
            id="lastName"
            (keyup.enter)="shiftFocus('email')"
            formControlName="last_name"
            autocomplete="off"
            [maxlength]="150"
            placeholder="Last Name"
          />
          <label for="lastName">Last Name</label>
        </div>

        <div class="form-floating">
          <input
            class="form-control"
            type="email"
            id="email"
            (keyup.enter)="shiftFocus('phone')"
            formControlName="email"
            autocomplete="off"
            placeholder="Email"
            [readOnly]="editContact"
          />
          <label for="email">Email</label>
        </div>

        <div class="form-floating">
          <input
            class="form-control"
            type="tel"
            minlength="10"
            maxlength="10"
            id="phone"
            formControlName="mobile_number"
            autocomplete="off"
            placeholder="Telephone"
            (input)="mobileNumberCheck()"
          />
          <label for="phone">Telephone</label>
          <div
            *ngIf="
              contactForm.controls['mobile_number'].invalid &&
              contactForm.controls['mobile_number'].touched
            "
          >
            <p class="text-danger">Mobile should be a 10-digit number</p>
          </div>
        </div>

        <div *ngIf="siteData?.length" class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="defaultContact"
            (change)="togglePrimary($event)"
          />
          <label class="form-check-label" for="defaultContact">
            Make Default for
            {{ siteData?.company_name ? siteData?.company_name : "site" }}
          </label>
        </div>
      </div>

      <div class="row row-cols-2 mt-2">
        <div class="col">
          @if(editContact){
          <button
            type="button"
            class="btn btn-dark btn-lg w-100"
            (click)="editContact = false; showAddForm = false"
          >
            Cancel
          </button>
          }@else {
          <button
            type="button"
            class="btn btn-dark btn-lg w-100"
            (click)="
              patrolReportUsers || addNewSiteContact
                ? onCloseDialogue(true)
                : contactForm.reset();
              showAddForm = !showAddForm
            "
          >
            {{
              patrolReportUsers || addNewSiteContact ? "Close" : "Back"
            }}</button
          >}
        </div>
        <div class="col">
          <button
            type="submit"
            class="btn btn-primary w-100 btn-lg"
            [disabled]="!contactForm.valid"
            (click)="editContact ? updateContact() : createContact()"
          >
            {{ editContact ? "Update" : "Add" }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div *ngIf="contactData && !editContact" class="card dialog-box">
  <div class="card-header">
    Contact Info
    <span class="float-end" (click)="onCloseDialogue()">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

  <div>
    <dl>
      <dt>Full Name</dt>
      <dd class="fw-bold">
        {{ contactData?.full_name }}
      </dd>
    </dl>
    <dl>
      <dt>
        Email
        <a href="mailto:{{ contactData?.email }}" class="me-3">
          <i class="fa-solid fa-envelope fa-2x text-primary"></i>
        </a>
      </dt>
      <dd>
        {{ contactData?.email }}
      </dd>
    </dl>
    <dl *ngIf="contactData?.mobile_number">
      <dt>
        Phone
        <a href="tel:+{{ contactData?.mobile_number }}" class="ms-3">
          <i class="fa-solid fa-phone-volume fa-2x text-success"></i>
        </a>
      </dt>
      <dd>
        {{ contactData?.mobile_number }}
      </dd>
    </dl>
  </div>
  <div class="row row-cols-2 mt-4">
    <div class="col">
      <button
        type="button"
        class="btn btn-danger btn-lg w-100"
        (click)="deleteContact()"
      >
        Delete
      </button>
    </div>
    <div class="col">
      <button
        type="submit"
        class="btn btn-primary w-100 btn-lg"
        (click)="onEditContact()"
      >
        Edit
      </button>
    </div>
  </div>
</div>
