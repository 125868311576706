import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { removeYIfYPresent } from '../../../global.variable';

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
  override transform(
    value?: any,
    format?: string,
    timezone?: string,
    locale?: string
  ): any {
    const customFormat = format || 'short';
    const currentYear = new Date().getFullYear();
    const eventYear = new Date(value).getFullYear();

    const dateTimeFormat =
      currentYear === eventYear
        ? removeYIfYPresent(customFormat)
        : customFormat;

    return super.transform(value, dateTimeFormat, timezone, locale);
  }
}
