<div class="card dialog-box">
  <div class="card-header">
    {{ updateView ? "Edit Client Details" : "Add New Client" }}
    <span class="float-end" (click)="onCloseDialogue()">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

  <form [formGroup]="clientForm" appFormEnterAction>
    <div class="form-floating mb-2">
      <input
        class="form-control"
        type="text"
        id="company_name"
        formControlName="company_name"
        autocomplete="off"
        placeholder="Company Name"
        maxlength="60"
        autofocus
      />
      <label for="company_name">Client Name</label>
    </div>
    <gtapp-address-lookup [addressForm]="clientForm"> </gtapp-address-lookup>
    <div class="row row-cols-2 mt-4">
      <div>
        <button
          type="button"
          class="btn btn-dark w-100 btn-lg"
          (click)="onCloseDialogue()"
        >
          Cancel
        </button>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-primary w-100 btn-lg"
          (click)="updateData()"
          [disabled]="!clientForm.valid"
        >
          Submit
        </button>
      </div>
    </div>
  </form>
</div>
