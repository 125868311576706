<div class="card dialog-box">
  <div class="card-header">
    <span class="float-end" (click)="onCloseDialogue()">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

  <form [formGroup]="addressForm" appFormEnterAction>
    <ng-container *ngIf="currentStep === 1">
      <div class="mb-2">
        <gtapp-auto-complete
          [data]="thirdPartyAddresses"
          placeHolder="Search Address"
          searchKeyword="full_address"
          [itemTemplate]="thirdPartyLookupTemplate"
          [initialValue]="selectedThirdPartyFullAddress"
          (inputChanged)="thridPartySearchAddress($event)"
          (selected)="thirdPartyAddressSelected($event)"
        >
        </gtapp-auto-complete>
      </div>

      <ng-template #thirdPartyLookupTemplate let-item>
        <div class="small fw-semibold">
          {{ item?.properties?.full_address }}
        </div>
      </ng-template>

      <div class="map-height" *ngIf="showMap">
        <gtapp-drag-map
          (emitData)="getLatLon($event)"
          [latLon]="latLon"
        ></gtapp-drag-map>
      </div>
      <div
        *ngIf="selectedLatlon?.lat || selectedLatlon?.lon"
        class="text-center small text-body-tertiary"
      >
        {{ selectedLatlon?.lat }}, {{ selectedLatlon?.lon }}
      </div>

      <div class="row row-cols-2 mt-1 g-2">
        <div class="col">
          <button
            type="button"
            class="btn btn-lg w-100 btn-secondary"
            (click)="showHideMap()"
            *ngIf="selectedLatlon"
          >
            My Location
          </button>
        </div>
        <div class="col">
          <button
            type="button"
            class="btn btn-lg w-100 btn-primary"
            (click)="
              findAddress();
              showFrom === 'fullAddressRequired' ? (currentStep = 2) : ''
            "
            [disabled]="!selectedLatlon"
          >
            Next
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentStep === 2">
      <div class="alert p-0 m-0 fw-bold mb-3 ms-2">Confirm Address Details</div>
      <div *ngIf="showFrom === 'fullAddressRequired'">
        <div>
          <div
            class="alert alert-danger"
            *ngIf="!addressForm.valid"
            style="font-weight: 500"
          >
            Unable to get exact address, please fill in missing information
          </div>
          <div class="d-flex flex-column gap-2">
            <div class="form-floating">
              <input
                required
                type="tel"
                class="form-control"
                id="address1"
                maxlength="30"
                formControlName="address1"
                autocomplete="off"
                placeholder="Street No."
              />
              <label for="address1">Street Number</label>
            </div>
            <div class="form-floating">
              <input
                required
                type="text"
                class="form-control"
                id="address2"
                maxlength="100"
                formControlName="address2"
                autocomplete="off"
                placeholder="Street"
              />
              <label for="address2">Street Name</label>
            </div>
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                id="address3"
                maxlength="100"
                formControlName="address3"
                autocomplete="off"
                placeholder="Unit"
              />
              <label for="address3">Address Prefix (Suite/Lot/Unit)</label>
            </div>
            <div class="form-floating">
              <input
                (input)="forceUppercaseConditionally($event)"
                required
                type="text"
                class="form-control"
                id="city_name"
                maxlength="100"
                formControlName="city_name"
                autocomplete="off"
                placeholder="City"
              />
              <label for="city">Suburb</label>
            </div>
            <div class="form-floating">
              <input
                required
                type="tel"
                class="form-control"
                id="postcode"
                maxlength="30"
                formControlName="postcode"
                autocomplete="off"
                placeholder="Post Code"
              />
              <label for="postcode">Post Code</label>
            </div>
            <div class="form-floating">
              <select class="form-select" required formControlName="state_code">
                <option *ngFor="let state of stateData" [value]="state.code">
                  {{ state.code }}
                </option>
              </select>
              <label for="">State</label>
            </div>

            <div class="row row-cols-2 mt-3">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-lg w-100 btn-dark"
                  (click)="currentStep = 1"
                >
                  Back
                </button>
              </div>
              <div class="col">
                <button
                  type="button"
                  class="btn btn-lg w-100 btn-primary"
                  [disabled]="!addressForm.valid"
                  (click)="updateData()"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </form>
</div>
