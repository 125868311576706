<ng-container *ngIf="barChartData?.length && maxChartValue">
  <div class="bar-chart">
    @for (bar of barChartData; track bar) {

    <div>
      <div class="value">{{ bar | misc : "getBarValue" : charInfo }}</div>

      <div
        class="bar"
        [ngStyle]="bar | misc : 'getBarStyle' : charInfo : maxChartValue"
      ></div>

      <div class="label">{{ bar | misc : "getBarLabel" : charInfo }}</div>
    </div>
    }
  </div>
  @if(averageValue){
  <span class="avg-value" [ngStyle]="{ bottom: averageValueInPx }">
    Average {{ averageValue }}</span
  >
  <div class="avg-dotted-line" [ngStyle]="{ bottom: averageValueInPx }"></div>

  }
</ng-container>
