@if(showLicensesList){
<ng-container *ngIf="licensesDataList?.length">
  <div
    [ngClass]="
      showFrom === 'profilePage'
        ? 'row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 row-cols-xxxl-5 g-3'
        : 'row row-cols-1 row-cols-lg-2 g-3'
    "
  >
    <div class="col" *ngFor="let licence of licensesDataList">
      <div
        class="license-card"
        [ngClass]="licence?.expiry_code === 2 ? 'expired-licence' : ''"
      >
        <div
          class="text-center"
          [ngClass]="licence?.expiry_code === 2 ? '' : 'pt-4'"
        >
          <span class="fw-bold">
            {{ licence?.expiry_code === 2 ? "EXPIRED" : "" }}
          </span>
        </div>

        <div class="float-end">
          <div class="fw-bold">
            <span class="display-6">{{ licence.issuer_state }}</span>
          </div>
        </div>

        <div class="card-body pb-1 p-2">
          <div class="text-center"></div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="me-3">
              <div>
                <dl class="mb-1">
                  <dt>Licence No.</dt>
                  <dd>
                    {{ licence.license_number }}
                  </dd>
                </dl>
              </div>

              <div>
                <dl class="mb-1">
                  <dt>Class</dt>
                  <dd>
                    {{ licence.license_class }}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <dl class="mb-1">
              <dt>Valid Till</dt>
              <dd>{{ licence.valid_till }}</dd>
            </dl>

            <span
              *ngIf="showFrom === 'profilePage'"
              class="text-end"
              (click)="deleteLicense.emit(licence)"
              title="Delete License"
            >
              <i class="fa-regular fa-trash-can fa-2x"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!licensesDataList?.length"></ng-container>
}@else{
<ng-container>
  <div class="card dialog-box">
    <div class="card-header">
      Add Security Licence

      <span class="float-end" (click)="closeDialogue('close')">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <form [formGroup]="licenseForm">
        <!-- State Dropdown -->
        <div class="form-floating mb-3">
          <select
            class="form-select form-select-lg"
            id="issuer_state"
            formControlName="issuer_state"
            (change)="selectState($event)"
          >
            <option
              *ngFor="let state of stateData"
              [value]="state.id"
              [attr.data-code]="state.code"
            >
              {{ state.code }}
            </option>
          </select>
          <label for="issuer_state">State</label>
        </div>

        <!-- Licence Class / Category -->
        <div class="mb-3">
          <gtapp-auto-complete
            [data]="licenseClassData"
            placeHolder="Licence Class / Category"
            searchKeyword="license_class_name"
            [initialValue]="licenseClassName"
            [itemTemplate]="licenceTemplate"
            (inputChanged)="onLicenceClassSearch($event)"
            (selected)="onlicenseSelect($event)"
          >
          </gtapp-auto-complete>
        </div>

        <ng-template #licenceTemplate let-item>
          <div>
            {{ item.license_class_name }}
          </div>
        </ng-template>

        <!-- Security Licence ID Number -->
        <div class="form-floating mb-3">
          <input
            class="form-control"
            type="text"
            id="license_number"
            formControlName="license_number"
            autocomplete="off"
            placeholder="Security Licence ID Number"
          />
          <label for="license_number">Security Licence ID Number</label>
        </div>

        <!-- Licence Expiry Date -->
        <div class="form-floating mb-3">
          <input
            class="form-control"
            type="date"
            id="valid_till"
            formControlName="valid_till"
            placeholder="Licence Expiry"
            [min]="validDate"
          />
          <label for="valid_till">Licence Expiry</label>
        </div>

        <!-- Action Buttons -->
        <div class="row row-cols-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-dark w-100 btn-lg"
              (click)="closeDialogue()"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-primary w-100 btn-lg"
              [disabled]="!licenseForm.valid"
              (click)="addLicense()"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
}
